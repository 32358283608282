import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { tagApi } from '../../api';

export const getAllTag: any = createAsyncThunk('getAllTag', async () => {
  const res = await tagApi.getAllTag();
  return res.data;
});

const tagProductSlice = createSlice({
  name: 'tag-product',
  initialState: {
    listProductWithTags: [],
  },
  reducers: {
    getProductWithTags(state: any, action) {
      state.listProductWithTags = [...current(state.listProductWithTags), action.payload];
    },
  },

  extraReducers: {
    [getAllTag.pending]: (state: any, action: any) => {
      //
    },
    [getAllTag.reject]: (state: any, action: any) => {
      //
    },
    [getAllTag.fulfilled]: (state: any, action: any) => {
      //
    },
  },
});

export const { getProductWithTags } = tagProductSlice.actions;

const { reducer: tagProductReducer } = tagProductSlice;

export default tagProductReducer;
