import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { styleColorApi } from '../../api/styleColorApi/styleColorApi';
import { textStyleColorApi } from '../../api/textStyleColorApi/textStyleColorApi';

export const getText: any = createAsyncThunk('text-style/getText', async (id) => {
  const res = await textStyleColorApi.getText(id);
  return res.data;
});

export const getFont: any = createAsyncThunk('text-style/getFont', async () => {
  const res = await textStyleColorApi.getFont();
  return res.data;
});

// ========== ADD ==========

export const addText: any = createAsyncThunk('text-style/addText', async (data) => {
  const res = await textStyleColorApi.addText(data);
  return res;
});

export const addFont: any = createAsyncThunk('text-style/addFont', async (data) => {
  const res = await textStyleColorApi.addFontText(data);
  return res;
});

export const addColor: any = createAsyncThunk('text-style/addColor', async (data) => {
  const res = await styleColorApi.addColor(data);
  return res;
});

// ========== EDIT ==========

export const editText: any = createAsyncThunk('text-style/editStyle', async (data) => {
  const res: any = await textStyleColorApi.updateText(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

export const editFont: any = createAsyncThunk('text-style/editFont', async (data) => {
  const res: any = await textStyleColorApi.updateFontText(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

export const editColor: any = createAsyncThunk('text-style/editColor', async (data) => {
  const res: any = await styleColorApi.editColor(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

// ========== DELETE ==========

export const deleteRowText: any = createAsyncThunk('text-style/deleteRowText', async (id) => {
  const res: any = await textStyleColorApi.deleteText(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

export const deleteRowFont: any = createAsyncThunk('text-style/deleteFontText', async (id) => {
  const res: any = await textStyleColorApi.deleteFontText(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

export const deleteRowColor: any = createAsyncThunk('text-style/deleteRowColor', async (id) => {
  const res: any = await styleColorApi.deleteRowColor(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

// =============================

const textStyleSlice = createSlice({
  name: 'text-style',
  initialState: {
    idRowTextEdit: null,
    idRowFontEdit: null,
    idRowColorEdit: null,
    dataText: [],
    dataFont: [],
    dataColor: [],
    dataStyleColor: [],
    textStatus: '',
    fontStatus: '',
    colorStatus: '',
    loading: false,
    listUpdateStyleColorText: [],
    dataSubStyleColor: [],
    listUpdateStyleColor: [],
  },

  reducers: {
    addRowText(state: any, action) {
      state.dataText = [...current(state.dataText), action.payload];
    },

    addRowFont(state: any, action) {
      state.dataFont = [...current(state.dataFont), action.payload];
    },

    addRowColor(state: any, action) {
      state.dataColor = [...current(state.dataColor), action.payload];
    },

    changeTextStatus(state: any, action) {
      state.textStatus = action.payload;
    },

    changeFontStatus(state: any, action) {
      state.fontStatus = action.payload;
    },

    changeColorStatus(state: any, action) {
      state.colorStatus = action.payload;
    },

    setRowTextEdit(state: any, action) {
      state.idRowTextEdit = action.payload;
      state.dataText = current(state.dataText)?.map((text: any) => {
        if (text.id === action.payload) {
          return {
            ...text,
            active: true,
          };
        } else {
          return {
            ...text,
            active: false,
          };
        }
      });
    },

    setRowFontEdit(state: any, action) {
      state.idRowFontEdit = action.payload;
      state.dataFont = current(state.dataFont)?.map((font: any) => {
        if (font.id === action.payload) {
          return {
            ...font,
            active: true,
          };
        } else {
          return {
            ...font,
            active: false,
          };
        }
      });
    },

    setRowColorEdit(state: any, action) {
      state.idRowColorEdit = action.payload;
      state.dataColor = current(state.dataColor)?.map((colorItem: any) => {
        if (colorItem.id === action.payload) {
          return {
            ...colorItem,
            active: true,
          };
        } else {
          return {
            ...colorItem,
            active: false,
          };
        }
      });
    },

    cancelText(state: any, action) {
      if (action.payload === 'edit') {
        state.dataText = current(state.dataText).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tempData: any = [...current(state.dataText)];
        tempData.pop();
        state.dataText = tempData;
      }
      state.textStatus = '';
      state.idRowTextEdit = null;
    },

    cancelFont(state: any, action) {
      if (action.payload === 'edit') {
        state.dataFont = current(state.dataFont).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tempDataFont: any = [...current(state.dataFont)];
        tempDataFont.pop();
        state.dataFont = tempDataFont;
      }
      state.fontStatus = '';
      state.idRowFontEdit = null;
    },

    cancelColor(state: any, action) {
      if (action.payload === 'edit') {
        state.dataColor = current(state.dataColor).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tmpDataSourceColor: any = [...current(state.dataColor)];
        tmpDataSourceColor.pop();
        state.dataColor = tmpDataSourceColor;
      }
      state.colorStatus = '';
      state.idRowColorEdit = null;
    },

    handleCheckboxStatus(state: any, action) {
      const { e, item } = action.payload;
      const data = item;
      state.dataStyleColor = current(state.dataStyleColor).map((item: any) => {
        if (item.id === data.id) {
          return {
            ...item,
            status: e.target.checked ? 1 : 2,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      //   ----------------

      const listSubStyleColor = [...current(state.listUpdateStyleColor)];

      if (listSubStyleColor?.length === 0) {
        state.listUpdateStyleColor = [
          {
            id: data.id,
            status: e.target.checked ? 1 : 2,
          },
        ];
      } else {
        const filterStyleColor = listSubStyleColor.filter((item: any) => item.id === data.id);
        if (filterStyleColor?.length === 0) {
          state.listUpdateStyleColor = [...listSubStyleColor, { id: data.id, status: e.target.checked ? 1 : 2 }];
        } else {
          const tmpSubStyleColor = listSubStyleColor?.map((item: any) => {
            if (data.id === item.id) {
              return {
                ...item,
                status: e.target.checked ? 1 : 2,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          state.listUpdateStyleColor = tmpSubStyleColor;
        }
      }
    },

    setDataText(state: any, action) {
      state.dataText = action.payload;
    },

    setDataFont(state: any, action) {
      state.dataFont = action.payload;
    },

    setDataColor(state: any, action) {
      state.dataColor = action.payload;
    },

    setDataStyleColor(state: any, action) {
      state.dataStyleColor = action.payload;
    },
  },

  extraReducers: {
    // GET DATA TEXT
    [getText.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getText.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getText.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataText = action.payload.content_text;
      state.dataColor = action.payload.color;
      state.dataFont = action.payload.font;
      state.dataStyleColor = action.payload.text_style_color;
    },

    // DELETE
    [deleteRowColor.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteRowColor.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteRowColor.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataColor = current(state.dataColor).filter((item: any) => item.id !== action.payload);
    },
  },
});

export const {
  addRowText,
  addRowFont,
  addRowColor,
  setDataText,
  setDataFont,
  setDataColor,
  setDataStyleColor,
  cancelText,
  cancelColor,
  cancelFont,
  setRowTextEdit,
  setRowFontEdit,
  setRowColorEdit,
  changeTextStatus,
  changeFontStatus,
  changeColorStatus,
  handleCheckboxStatus,
} = textStyleSlice.actions;
const { reducer: textStyleReducer } = textStyleSlice;

export default textStyleReducer;
