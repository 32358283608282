import axiosClient from "../axiosClient";

export type DataPayment = {
  order_id: number;
  last_name: string;
  first_name: string;
  last_name_furigana: string;
  first_name_furigana: string;
  zipcode: number;
  prefecture: string;
  city: string;
  address: string;
  email: string;
  notes: string;
  payment_method: number;
  total_payment: number;
};

export const paymentApi = {
  async addPayment(data: DataPayment) {
    const url = "/customer/address";
    return axiosClient.post(url, data);
  },

  async afterPaymentSuccess(id: number) {
    const url = `/customer/payment-success/${id}`;
    return axiosClient.get(url);
  },

  async paymentShipInfo(page: number) {
    const url = `/address-ship-info?page=${page}`;
    return axiosClient.get(url);
  },

  async updateInfoPayment(data: any) {
    const url = "orders/set-payment-order";
    return axiosClient.post(url, data);
  },
};
