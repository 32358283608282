import { Navigate, Outlet } from 'react-router-dom';
import { getLocalStorage } from '../utils/localStorage';
import patchDynamic from '../helper/pathDynamic';

const ProtectedRoutes = () => {
  const user = getLocalStorage('access_token');
  const role_user = getLocalStorage('role_user');

  //   return user === null ? <Navigate to={'/login'} /> : <Outlet />;
  return user === null && role_user === null ? <Navigate to={`/login-${patchDynamic}`} /> : <Outlet />;
};

export default ProtectedRoutes;
