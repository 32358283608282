import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { styleColorApi } from '../../api/styleColorApi/styleColorApi';

export const getStyleById: any = createAsyncThunk('style-color/getStyleById', async (id) => {
  const res = await styleColorApi.getStyleById(id);
  return res.data;
});

export const getItemById: any = createAsyncThunk('style-color/getItemById', async (id) => {
  const res = await styleColorApi.getItemById(id);
  return res.data;
});

export const addStyle: any = createAsyncThunk('style-color/addStyle', async (data) => {
  const res = await styleColorApi.addStyle(data);
  return res;
});

export const addSubStyle: any = createAsyncThunk('style-color/addSubStyle', async (data) => {
  const res = await styleColorApi.addStyle(data);
  return res;
});

export const addColor: any = createAsyncThunk('style-color/addColor', async (data) => {
  const res = await styleColorApi.addColor(data);
  return res;
});

export const editStyle: any = createAsyncThunk('style-color/editStyle', async (data) => {
  const res: any = await styleColorApi.editStyle(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

export const editSubStyle: any = createAsyncThunk('style-color/editSubStyle', async (data) => {
  const res: any = await styleColorApi.editStyle(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

export const editColor: any = createAsyncThunk('style-color/editColor', async (data) => {
  const res: any = await styleColorApi.editColor(data);
  if (res.status === 'success') {
    return {
      status: 'success',
      data,
    };
  }
});

export const deleteRowStyle: any = createAsyncThunk('style-color/deleteRowStyle', async (id) => {
  const res: any = await styleColorApi.deleteRowStyle(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

export const deleteRowSubStyle: any = createAsyncThunk('style-color/deleteRowSubStyle', async (id) => {
  const res: any = await styleColorApi.deleteRowStyle(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

export const deleteRowColor: any = createAsyncThunk('style-color/deleteRowColor', async (id) => {
  const res: any = await styleColorApi.deleteRowColor(id);
  if (res.status === 'success') {
    return id;
  } else {
    return undefined;
  }
});

const styleColorSlice = createSlice({
  name: 'style-color',
  initialState: {
    itemById: {},
    idRowEdit: null,
    idRowSubStyleEdit: null,
    idRowColorEdit: null,
    dataStyle: [],
    dataSubStyle: [],
    dataStyleById: [],
    dataColor: [],
    dataStyleColor: [],
    dataSubStyleColor: [],
    styleStatus: '',
    subStyleStatus: '',
    colorStatus: '',
    listUpdateStyleColor: [],
    listUpdateSubStyleColor: [],
    loading: false,
  },
  reducers: {
    addRowStyle(state: any, action) {
      /* Move input add style to the head */
      // const temp = [...current(state.dataStyle)]
      // temp.unshift(action.payload)
      // state.dataStyle = [...temp];
      state.dataStyle = [...current(state.dataStyle), action.payload];
    },

    addRowSubStyle(state: any, action) {
      state.dataSubStyle = [...current(state.dataSubStyle), action.payload];
    },

    addRowColor(state: any, action) {
      /* Move input add color to the head */
      // const temp = [...current(state.dataColor)]
      // temp.unshift(action.payload)
      // state.dataColor = [...temp];
      state.dataColor = [...current(state.dataColor), action.payload];
    },

    changeStyleStatus(state: any, action) {
      state.styleStatus = action.payload;
    },

    changeSubStyleStatus(state: any, action) {
      state.subStyleStatus = action.payload;
    },

    changeColorStatus(state: any, action) {
      state.colorStatus = action.payload;
    },

    setRowEdit(state: any, action) {
      state.idRowEdit = action.payload;

      state.dataStyle = current(state.dataStyle)?.map((styleItem: any) => {
        if (styleItem.id === action.payload) {
          return {
            ...styleItem,
            active: true,
          };
        } else {
          return {
            ...styleItem,
            active: false,
          };
        }
      });
    },

    setRowSubStyleEdit(state: any, action) {
      state.idRowSubStyleEdit = action.payload;
      state.dataSubStyle = current(state.dataSubStyle)?.map((subStyleItem: any) => {
        if (subStyleItem.id === action.payload) {
          return {
            ...subStyleItem,
            active: true,
          };
        } else {
          return {
            ...subStyleItem,
            active: false,
          };
        }
      });
    },

    setRowColorEdit(state: any, action) {
      state.idRowColorEdit = action.payload;
      state.dataColor = current(state.dataColor)?.map((colorItem: any) => {
        if (colorItem.id === action.payload) {
          return {
            ...colorItem,
            active: true,
          };
        } else {
          return {
            ...colorItem,
            active: false,
          };
        }
      });
    },

    cancelStyle(state: any, action) {
      if (action.payload === 'edit') {
        state.dataStyle = current(state.dataStyle).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tmpDataSourceStyle: any = [...current(state.dataStyle)];
        tmpDataSourceStyle.pop();
        state.dataStyle = tmpDataSourceStyle;
      }
      state.styleStatus = '';
      state.idRowEdit = null;
    },

    cancelSubStyle(state: any, action) {
      if (action.payload === 'edit') {
        state.dataSubStyle = current(state.dataSubStyle).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tmpDataSourceStyle: any = [...current(state.dataSubStyle)];
        tmpDataSourceStyle.pop();
        state.dataSubStyle = tmpDataSourceStyle;
      }
      state.subStyleStatus = '';
      state.idRowSubStyleEdit = null;
    },

    cancelColor(state: any, action) {
      if (action.payload === 'edit') {
        state.dataColor = current(state.dataColor).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tmpDataSourceColor: any = [...current(state.dataColor)];
        tmpDataSourceColor.pop();
        state.dataColor = tmpDataSourceColor;
      }
      state.colorStatus = '';
      state.idRowColorEdit = null;
    },

    handleCheckboxStatus(state: any, action) {
      const { e, data } = action.payload;
      state.dataStyleColor = current(state.dataStyleColor).map((item: any) => {
        if (item.id === data.id) {
          return {
            ...item,
            status: e.target.checked ? 1 : 2,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      //   ----------------

      const listStyleColor = [...current(state.listUpdateStyleColor)];

      if (listStyleColor?.length === 0) {
        state.listUpdateStyleColor = [
          {
            id: data.id,
            status: e.target.checked ? 1 : 2,
          },
        ];
      } else {
        const filterStyleColor = listStyleColor.filter((item: any) => item.id === data.id);
        if (filterStyleColor?.length === 0) {
          state.listUpdateStyleColor = [...listStyleColor, { id: data.id, status: e.target.checked ? 1 : 2 }];
        } else {
          const tmpStyleColor = listStyleColor?.map((item: any) => {
            if (data.id === item.id) {
              return {
                ...item,
                status: e.target.checked ? 1 : 2,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          state.listUpdateStyleColor = tmpStyleColor;
        }
      }
    },

    handleCheckboxSubStyleStatus(state: any, action) {
      const { e, data } = action.payload;
      state.dataSubStyleColor = current(state.dataSubStyleColor).map((item: any) => {
        if (item.id === data.id) {
          return {
            ...item,
            status: e.target.checked ? 1 : 2,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      //   ----------------

      const listSubStyleColor = [...current(state.listUpdateSubStyleColor)];

      if (listSubStyleColor?.length === 0) {
        state.listUpdateSubStyleColor = [
          {
            id: data.id,
            status: e.target.checked ? 1 : 2,
          },
        ];
      } else {
        const filterStyleColor = listSubStyleColor.filter((item: any) => item.id === data.id);
        if (filterStyleColor?.length === 0) {
          state.listUpdateSubStyleColor = [...listSubStyleColor, { id: data.id, status: e.target.checked ? 1 : 2 }];
        } else {
          const tmpSubStyleColor = listSubStyleColor?.map((item: any) => {
            if (data.id === item.id) {
              return {
                ...item,
                status: e.target.checked ? 1 : 2,
              };
            } else {
              return {
                ...item,
              };
            }
          });
          state.listUpdateSubStyleColor = tmpSubStyleColor;
        }
      }
    },

    setDataStyleColor(state: any, action) {
      state.dataStyleColor = action.payload;
    },

    setDataSubStyleColor(state: any, action) {
      state.dataSubStyleColor = action.payload;
    },
  },

  extraReducers: {
    // GET ITEM BY ID
    [getItemById.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getItemById.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getItemById.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.itemById = action.payload;
      state.dataStyle = action.payload.styles.map((item: any) => {
        return {
          ...item,
          active: false,
        };
      });
      state.dataColor = action.payload.colors.map((item: any) => {
        return {
          ...item,
          active: false,
        };
      });
      state.dataStyleColor = action.payload.style_colors;
    },

    // STYLE + COLOR
    /* Add */
    [addStyle.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [addStyle.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [addStyle.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Edit */
    [editStyle.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [editStyle.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [editStyle.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Delete */
    [deleteRowStyle.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteRowStyle.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteRowStyle.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataStyle = current(state.dataStyle).filter((item: any) => item.id !== action.payload);
    },

    /* --------- */

    /* Add */
    [addColor.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [addColor.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [addColor.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Edit */
    [editColor.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [editColor.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [editColor.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Delete */
    [deleteRowColor.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteRowColor.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteRowColor.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataColor = current(state.dataColor).filter((item: any) => item.id !== action.payload);
    },

    // GET DATA SUB STYLE
    [getStyleById.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getStyleById.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getStyleById.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataStyleById = action.payload;
      state.dataSubStyle = action.payload.sub_style;
      state.dataSubStyleColor = action.payload.sub_style_color;
    },

    // EDIT SUB STYLE
    [editSubStyle.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [editSubStyle.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [editSubStyle.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    // DELETE SUB STYLE
    [deleteRowSubStyle.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteRowSubStyle.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteRowSubStyle.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataSubStyle = current(state.dataSubStyle).filter((item: any) => item.id !== action.payload);
    },
  },
});

export const {
  addRowStyle,
  addRowSubStyle,
  changeStyleStatus,
  changeSubStyleStatus,
  setRowEdit,
  setRowSubStyleEdit,
  cancelStyle,
  cancelSubStyle,
  changeColorStatus,
  cancelColor,
  addRowColor,
  setRowColorEdit,
  handleCheckboxStatus,
  handleCheckboxSubStyleStatus,
  setDataStyleColor,
  setDataSubStyleColor,
} = styleColorSlice.actions;
const { reducer: styleColorReducer } = styleColorSlice;

export default styleColorReducer;
