export const textData = [
  {
    key: '1',
    status: 'ダッシュボードのメニューとできること',
    url: 'https://hc.fortuna-inc.jp/sec/archives/docs/27',
    // render: (_: any, record: any, index: any) => <a href="#">abc</a>,
  },
  {
    key: '2',
    status: '商品の画像を登録する方法',
    url: 'https://hc.fortuna-inc.jp/sec/archives/docs/29',
  },
  {
    key: '3',
    status: 'スタイルの画像と追加金額を設定する方法',
    url: 'https://hc.fortuna-inc.jp/sec/archives/docs/30',
  },
  {
    key: '4',
    status: '登録した商品とスタイルをプレビューする方法',
    url: 'https://hc.fortuna-inc.jp/sec/archives/docs/31',
  },
];
