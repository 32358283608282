// import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout, Table, Space, notification } from "antd";

// import { orderApi } from "../../../api";
import { paymentApi } from "../../../api/paymentApi/paymentApi";

const { Content } = Layout;

export const PaymentInfo = () => {
  const { id_payment } = useParams();
  const [api, showPopup] = notification.useNotification();

  // Alert
  //   const alertSuccess = (message: string) => {
  //     api.success({
  //       message,
  //       placement: "topRight",
  //       duration: 1,
  //     });
  //   };
  //   const alertFail = (message: string) => {
  //     api.error({
  //       message,
  //       placement: "topRight",
  //       duration: 3,
  //     });
  //   };

  const [infoPayment, setInfoPayment] = useState([
    {
      orderAddress: null,
      orderId: 0,
      payment_method: 0,
      payment_state: 0,
      ship_status: 0,
    },
  ]);

  const findOrder = (id: number, arr: any) => {
    const temp = arr?.filter((val: any) => val.orderId === id);
    return temp;
  };

  const getInfoPaymentSuccess = async () => {
    try {
      const resData: any = await paymentApi.paymentShipInfo(1);
      if (resData.status === "success") {
        const temp = findOrder(Number(id_payment), resData.data.data);
        setInfoPayment([...temp]);
      }
    } catch (err) {
      console.log("Fail.");
    }
  };

  useEffect(() => {
    getInfoPaymentSuccess();
    // eslint-disable-next-line
  }, []);

  //   const getColumnSearchProps = (dataIndex: string) => ({
  //     render: (text: any, record: any, index: any) => {
  //       if (dataIndex === "datetime") {
  //         return record?.created_at ? (
  //           <span>{moment(record?.created_at).format("YYYY.MM/DD HH:mm")}</span>
  //         ) : (
  //           <span></span>
  //         );
  //       }
  //     },
  //   });

  //   const getDataOrders = async (id: number) => {
  //     try {
  //       const res: any = await orderApi.getOrdersById(id);
  //       if (res.status === "success") {
  //       }
  //     } catch (err) {
  //       alertFail("Download Fail!");
  //     }
  //   };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "orderId",
      align: "center",
      key: "orderId",
    },
    {
      title: "お支払い方法",
      dataIndex: "payment_method",
      align: "center",
      key: "payment_method",
      render: (data: any) => (
        <span>
          {data === 0 ? "不明" : data === 1 ? "商品受け取り時" : "振込"}
        </span>
      ),
    },
    {
      title: "支払状況",
      dataIndex: "payment_state",
      align: "center",
      key: "payment_state",
      render: (data: any) => (
        <span>{data === 0 ? "未払い" : "支払い済み"}</span>
      ),
    },
    {
      title: "配送状況",
      dataIndex: "ship_status",
      align: "center",
      key: "ship_status",
      render: (data: any) => <span>{data === 0 ? "未発送" : "発送済み"}</span>,
    },
  ];

  return (
    <Layout className="style-color" style={{ padding: "0 24px" }}>
      {showPopup}
      <Content className="site-layout-background content-wrap">
        <h3
          style={{ fontSize: "25px", fontWeight: "bold", marginBottom: "15px" }}
        >
          ご注文情報
        </h3>
        <Table
          rowKey="id"
          //   loading={loadingOrder}
          columns={columns}
          dataSource={infoPayment}
          pagination={false}
        />
      </Content>
    </Layout>
  );
};
