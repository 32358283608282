import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSeoTag,
  addSeoTag,
  updateSeoTagById,
  addRow,
  editRow,
  changeStatus,
  cancelRow,
  deleteSeoTagById,
  setDataSeoTagsTmp,
} from "../../../redux/services/tagSeoSlice";
import { DataTypeSeoTags } from "../../../types/seoTags";
import type { ColumnsType } from "antd/es/table";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Layout,
  Input,
  Form,
  notification,
  Table,
  Space,
  Popconfirm,
} from "antd";

export const TagSeo = () => {
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const dispatch = useDispatch();
  const [api, showPopup] = notification.useNotification();
  const [formSeoTags] = Form.useForm();

  const { dataSeoTags, dataSeoTagsTmp, loading, status, idRowEdit } =
    useSelector((state: any) => state.seoTagReducer);

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      // if (dataIndex === "id") {
      //   if (record.active) {
      //     return (
      //       <Form.Item name="id">
      //         <Input style={{ textAlign: "center" }} />
      //       </Form.Item>
      //     );
      //   } else {
      //     return <span>{record.id}</span>;
      //   }
      // }
      if (dataIndex === "name") {
        if (record.active) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return <span>{record.name}</span>;
        }
      }
      if (dataIndex === "content") {
        if (record.active) {
          return (
            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                  message: "データを入力して下さい。",
                },
              ]}
            >
              <Input style={{ textAlign: "center" }} />
            </Form.Item>
          );
        } else {
          return <span>{record.content}</span>;
        }
      }
      if (dataIndex === "actions") {
        if (record.active && status === "add") {
          return (
            <Space>
              <Button
                danger
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(cancelRow(status === "edit" ? "edit" : record.id));
                  formSeoTags.resetFields();
                }}
              />
              <Button
                disabled={false}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
            </Space>
          );
        } else {
          return (
            <Space>
              <Button
                danger
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<CloseCircleOutlined />}
                title="Cancel"
                onClick={() => {
                  dispatch(cancelRow(status === "edit" ? "edit" : record.id));
                  formSeoTags.resetFields();
                }}
              />
              <Button
                type="default"
                icon={<EditOutlined />}
                title="Edit"
                onClick={() => {
                  dispatch(changeStatus("edit"));
                  dispatch(editRow(record.id));
                  dispatch(setDataSeoTagsTmp([]));
                  formSeoTags.setFieldsValue({
                    // key: index,
                    name: record.name,
                    content: record.content,
                  });
                }}
              />
              <Button
                disabled={!record.active}
                type="primary"
                htmlType="submit"
                ghost
                icon={<SaveOutlined />}
                title="Save"
              />
              <Popconfirm
                title="削除しますか？"
                okText="はい"
                cancelText="いいえ"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  handleDeleteRow(record.id);
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title="Delete"
                ></Button>
              </Popconfirm>
            </Space>
          );
        }
      }
    },
  });

  const columns: ColumnsType<DataTypeSeoTags> = [
    {
      title: "順",
      width: "5%",
      dataIndex: "no",
      align: "center",
      render: (data: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    // {
    //   title: "Key",
    //   dataIndex: "key",
    //   width: "10%",
    //   className: "hidden-row",
    //   align: "center",
    //   ...getColumnSearchProps("key"),
    // },
    {
      title: "サービス名",
      dataIndex: "name",
      width: "20%",
      align: "center",
      ...getColumnSearchProps("name"),
    },
    {
      title: "サービス内容",
      width: "50%",
      dataIndex: "content",
      align: "center",
      ...getColumnSearchProps("content"),
    },
    {
      title: "操作",
      width: "25%",
      dataIndex: "actions",
      align: "center",
      ...getColumnSearchProps("actions"),
    },
  ];

  const handleAddRow = async () => {
    formSeoTags.resetFields();
    const rowsInput: any = {
      id: (dataSeoTags.length + 1).toString(),
      name: "",
      content: "",
      location: 1,
      status: 1,
      active: true,
    };
    dispatch(addRow(rowsInput));
    dispatch(changeStatus("add"));
  };

  const handleDeleteRow = async (id: any) => {
    try {
      const res = await dispatch(deleteSeoTagById(id));
      if (res.payload.status === "success") {
        dispatch(getAllSeoTag());
        formSeoTags.resetFields();
        alertSuccess(res.payload.data.message);
      }
      if (res.payload.status === "fail") {
        dispatch(getAllSeoTag());
        formSeoTags.resetFields();
        alertFail(res.payload.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values: any) => {
    dispatch(setDataSeoTagsTmp([]));
    const newTag = {
      ...values,
      location: 1,
    };
    try {
      if (status === "add") {
        const res = await dispatch(addSeoTag(newTag));
        if (res.payload.status === "success") {
          formSeoTags.resetFields();
          dispatch(getAllSeoTag());
          alertSuccess(res.payload.data.message);
        }
      } else {
        const res = await dispatch(
          updateSeoTagById({
            id: idRowEdit,
            body: { ...newTag },
          })
        );
        if (res.payload.status === "success") {
          formSeoTags.resetFields();
          dispatch(getAllSeoTag());
          alertSuccess(res.payload.data.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getAllSeoTag());
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Layout
      className="style-color min-height-layout"
      style={{ padding: "24px" }}
    >
      {showPopup}
      <p className="title-view">タグSEO</p>
      <div className="site-layout-background content-wrap">
        <div className="table-wrap form-upload-style">
          <Form form={formSeoTags} onFinish={onFinish}>
            <Table
              rowKey="id"
              loading={loading}
              columns={columns}
              dataSource={[...dataSeoTags, ...dataSeoTagsTmp]}
              bordered
              pagination={false}
              className="text-center"
              title={() => (
                <Button
                  type="primary"
                  onClick={handleAddRow}
                  icon={<PlusCircleOutlined />}
                >
                  サービス追加
                </Button>
              )}
            ></Table>
          </Form>
          <div style={{ color: "#00000091", marginTop: "10px" }}>
            (*) If using Google Analytics, the default service name is gaCode,
            the content is Google Analytics ID
          </div>
        </div>
      </div>
    </Layout>
  );
};
