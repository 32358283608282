import headerDynamic from "../../helper/headerDynamic";

export default function HeaderCustomer() {
  return (
    // <header className="header_client">
    //   <a href="/">
    //     <img src={logo} alt="" />
    //     <h2>
    //       OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL<sup>®</sup>
    //     </h2>
    //   </a>
    // </header>

     <header className={headerDynamic.status ? 'header_client_rawlings' : 'header_client'}>
      <a href="/">
        <img src={headerDynamic.image_logo} alt="" />
        <h2>
          {headerDynamic.title_header}
        </h2>
      </a>
    </header>
  );
}
