import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../api';

export const getInformation: any = createAsyncThunk('user/getInformation', async () => {
    const res = await userApi.getInfomation();
    return res.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userInfor: {},
        loadingUser: false,
    },
    reducers: {},
    extraReducers: {
        [getInformation.pending]: (state: any, action: any) => {
            state.loadingUser = true;
        },
        [getInformation.reject]: (state: any, action: any) => {
            state.loadingUser = false;
        },
        [getInformation.fulfilled]: (state: any, action: any) => {
            state.loadingUser = false;
            state.userInfor = action.payload;
        },
    },
});

const { reducer: userReducer } = userSlice;

export default userReducer;
