import axiosClient from "../axiosClient";

export const seoTagsApi = {
  async getAllSeoTagCustomer() {
    const url = "/customer/seo-tags";
    return axiosClient.get(url);
  },

  async getAllSeoTag() {
    const url = "/seo-tags";
    return axiosClient.get(url);
  },

  async getSeoTagById(id: any) {
    const url = `/seo-tags/${id}`;
    return axiosClient.get(url);
  },

  async addSeoTag(data: any) {
    const url = "/seo-tags";
    return axiosClient.post(url, data);
  },
  async updateSeoTagById(data: any) {
    const url = `/seo-tags/${data.id}`;
    return axiosClient.put(url, data.body);
  },
  async deleteSeoTagById(id: number) {
    const url = `/seo-tags/delete-by-id/${id}`;
    return axiosClient.delete(url);
  },
};
