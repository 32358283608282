import axiosClient from '../axiosClient';

export const authApi = {
  async login(data: any) {
    const url = `/login`;
    return axiosClient.post(url, data);
  },

  async logout() {
    const url = `/logout`;
    return axiosClient.post(url);
  },

  async register(data: any) {
    const url = '/register';
    return axiosClient.post(url, data);
  },
};
