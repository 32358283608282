import { ChildrenProps } from '../types';

const DefaultLayout = ({ children }: ChildrenProps) => {
  return (
    <div>
      <div className="container">{children}</div>
    </div>
  );
};

export default DefaultLayout;
