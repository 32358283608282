import { Layout } from "antd";
import "../assets/styles/Admin/style.scss";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import overLoader from "../components/overLoader";
import { useSelector } from "react-redux";

const AdminLayout = ({ children }: any) => {
  const { loadingAddItemStatus } = useSelector(
    (state: any) => state.productReducer
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {loadingAddItemStatus ? overLoader() : ""}
      <Header />
      <Layout>
        <Sidebar />
        {children}
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
