import axiosClient from '../axiosClient';

export const textStyleColorApi = {
  // ========= TEXT =========

  async getText(styleId: any) {
    const url = `/styles/get-style-with-text-style/${styleId}`;
    return axiosClient.get(url);
  },

  async addText(data: any) {
    const url = `/styles`;
    return axiosClient.post(url, data);
  },

  async updateText(data: any) {
    const url = `/styles/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async deleteText(id: any) {
    const url = `/styles/${id}`;
    return axiosClient.delete(url);
  },

  // ========= FONT TEXT =========

  async getFont() {
    const url = `/fonts`;
    return axiosClient.get(url);
  },

  async addFontText(data: any) {
    const url = `/fonts`;
    return axiosClient.post(url, data);
  },

  async updateFontText(data: any) {
    const url = `/fonts/${data.id}`;
    return axiosClient.put(url, data.body);
  },

  async deleteFontText(idDelete: any) {
    const url = `/fonts/${idDelete}`;
    return axiosClient.delete(url);
  },

  // ========= COLOR TEXT =========

  async addColorText(data: any) {
    const url = `/colors`;
    return axiosClient.post(url, data);
  },

  async updateColorText(data: any) {
    const url = `/colors/${data.id}`;
    return axiosClient.put(url, data);
  },

  async deleteColorText(data: any) {
    const url = `/colors/${data.id}`;
    return axiosClient.post(url);
  },
};
