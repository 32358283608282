import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Select, notification } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getOrdersWithPagination } from "../../../redux/services/orderSlice";
import { paymentApi } from "../../../api/paymentApi/paymentApi";
import { orderApi } from "../../../api";
import formatJapan from "../../../helper/formatJapan";

const OrderEditModal = ({ orderId, searchParams }: any) => {
  const [paymentState, setPaymentState] = useState(0);
  const [shipStatus, setShipStatus] = useState(0);
  const [dataModal, setDataModal] = useState({
    order_id: 0,
    last_name: "",
    first_name: "",
    last_name_furigana: "",
    first_name_furigana: "",
    zipcode: "",
    prefecture: "",
    city: "",
    address: "",
    email: "",
    notes: null,
    total_payment: 0,
  });
  const [formProduct] = Form.useForm();
  const dispatch = useDispatch();

  const [api, showPopup] = notification.useNotification();
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const onFinish = async (orderId: any) => {
    const dataEdit = {
      order_id: orderId,
      ship_status: shipStatus,
      payment_state: paymentState,
      payment_method: 2,
    };

    try {
      const res: any = await paymentApi.updateInfoPayment(dataEdit);
      if (res.status === "success") {
        alertSuccess("保存しました。");
        dispatch(
          getOrdersWithPagination({
            page_index: searchParams.get("page_index"),
            page_size: searchParams.get("page_size"),
          })
        );
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getDataModal = async () => {
    try {
      const resData: any = await orderApi.getOrdersDetail(orderId);
      if (resData.status === "success") {
        formProduct.setFieldsValue({
          payment_state: Number(resData.data.payment_state),
          ship_status: Number(resData.data.ship_status),
          address: resData.data.ship_address.address,
        });
        setDataModal(resData.data.ship_address);
      }
    } catch (err) {
      console.log("Fail.");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    getDataModal();
  };

  const handleOk = () => {
    formProduct
      .validateFields()
      .then(() => {
        onFinish(orderId);
        formProduct.resetFields();
        setIsModalOpen(false);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {showPopup}
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={"middle"}
        onClick={showModal}
      >
        詳細
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={<p style={{ letterSpacing: "-2px" }}>更新</p>}
        cancelText="キャンセル"
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            fontWeight: "500",
            margin: "15px auto",
          }}
        >
          注文情報変更
        </span>

        <Form
          form={formProduct}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{
            lg: { span: 5 },
          }}
        >
          <Form.Item name="id" label="ID" style={{ marginBottom: "5px" }}>
            {dataModal?.order_id}
          </Form.Item>
          <Form.Item
            name="address"
            label="住所"
            style={{ marginBottom: "5px" }}
          >
            <p>{dataModal?.zipcode || "_"}</p>
            <p>
              {dataModal?.prefecture || "_"},{dataModal?.city || "_"}
            </p>
            <p>{dataModal?.address || "_"}</p>
          </Form.Item>
          <Form.Item
            name="name"
            label="お客様の名前"
            style={{ marginBottom: "5px" }}
          >
            {`${dataModal?.first_name}${dataModal?.last_name}` || "_"}
          </Form.Item>
          <Form.Item
            name="name_furigana"
            label="読み仮名"
            style={{ marginBottom: "5px" }}
          >
            {`${dataModal?.first_name_furigana}${dataModal?.last_name_furigana}` ||
              "_"}
          </Form.Item>
          <Form.Item
            name="total_payment"
            label="合計金額"
            style={{ marginBottom: "5px" }}
          >
            {formatJapan.format(dataModal?.total_payment || 0)}
          </Form.Item>
          <Form.Item
            name="notes"
            label="注文メモ"
            style={{ marginBottom: "10px" }}
          >
            {dataModal?.notes || "_"}
          </Form.Item>

          <Form.Item
            name="payment_state"
            label="支払状況"
            rules={[
              {
                required: true,
                message: "データを入力して下さい。",
              },
            ]}
          >
            <Select
              onChange={(e) => setPaymentState(Number(e))}
              options={[
                { value: 0, label: "未払い" },
                { value: 1, label: "支払い済み" },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="ship_status"
            label="配送状況"
            rules={[
              {
                required: true,
                message: "データを入力して下さい。",
              },
            ]}
          >
            <Select
              onChange={(e) => setShipStatus(Number(e))}
              options={[
                { value: 0, label: "未発送" },
                { value: 1, label: "発送済み" },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrderEditModal;
