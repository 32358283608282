import { useState, useMemo } from "react";
import { Space, ColorPicker } from "antd";
import type { ColorPickerProps, GetProp } from "antd";

type Color = GetProp<ColorPickerProps, "value">;
type Format = GetProp<ColorPickerProps, "format">;

const ColorInput = (props: any) => {
  const [colorHex, setColorHex] = useState<Color>(props.colorNow ? props.colorNow : "#ffffff");
  const [formatHex, setFormatHex] = useState<Format | undefined>("hex");

  const sendData = (data: any) => {
    props.getColorFromInput(data);
  };

  useMemo<any>(() => {
    typeof colorHex === "string"
      ? sendData(colorHex)
      : sendData(colorHex?.toHexString());
  }, [colorHex]);

  return (
    <Space>
      <ColorPicker
        format={formatHex}
        value={colorHex}
        onChange={setColorHex}
        onFormatChange={setFormatHex}
      />
    </Space>
  );
};

export default ColorInput;
