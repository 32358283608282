import { Button, Form, Input, Checkbox, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { authApi } from '../../../api';
import './login.scss';
import patchDynamic from '../../../helper/pathDynamic';
import { useState } from 'react';
import overLoader from '../../../components/overLoader';

export const Login = () => {
  const [api, showPopup] = notification.useNotification();
  const [statusButtonLogin, setStatusButtonLogin] = useState<boolean>(true);
  const [statusLogin, setStatusLogin] = useState<boolean>(false);
  const alertLoginSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertLoginWarning = (message: string) => {
    api.warning({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };
  const alertLoginFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Handle Data Login
  const navigate = useNavigate();
  const handleVerifyCapcha = (value: any) => {
    setStatusButtonLogin(false);
  };
  const handleLogin = async (dataLogin: any) => {
    // try {
    //   const res: any = await authApi.login(dataLogin);
    //   if (res?.status === 'success') {
    //     setStatusLogin(true);
    //     setTimeout(() => {
    //       setStatusLogin(false);
    //       navigate(`/otp-confirm-${patchDynamic}`, {
    //         state: {
    //           dataLogin,
    //         },
    //       });
    //     }, 1000);
    //   }
    // } catch (err) {
    //   alertLoginFail('Login Fail');
    // }

    try {
      const res: any = await authApi.login(dataLogin);
      if (res?.status === 'success') {
        setStatusLogin(true);
        localStorage.setItem('access_token', JSON.stringify(res.data?.access_token));
        localStorage.setItem('expires_at', JSON.stringify(res.data?.expires_at));
        localStorage.setItem('email', JSON.stringify(dataLogin?.email));
        localStorage.setItem('role_user', JSON.stringify(res.data?.role));

        setTimeout(() => {
          setStatusLogin(false);
          alertLoginSuccess('ログインが成功しました。');
          setTimeout(() => {
            setStatusButtonLogin(true);
            navigate(`/admin-${patchDynamic}`);
          }, 500);
        }, 1000);
      }
    } catch (err: any) {
      if (err.response.data.data.number_attempts) {
        alertLoginFail(
          `ログイン情報が間違っています。アカウントロックされる前に、現在から後２４時間まで、あと${err.response.data.data.number_attempts}回ログインできます。`,
        );
      } else if (err.response.data.data.time_out) {
        alertLoginWarning(
          `アカウントが24時間ロックされました。${moment(err.response.data.data.time_out).format(
            'YYYY.MM/DD HH:mm',
          )}にもう一度お試しいただく。`,
        );
      } else {
        alertLoginFail('アカウントまたはパスワードが間違っています');
      }
    }
  };

  // Handle Submit
  const onFinish = (values: any) => {
    handleLogin({
      email: values.email,
      password: values.password,
    });
  };

  const loginSpam = () => {
    alertLoginFail('Login Fail');
    console.log('Login Fail!');
  };

  return (
    <div className="wrapper-login">
      {statusLogin ? overLoader() : ''}
      <div className="form-login">
        {showPopup}
        <h2>Color Simulation Login</h2>
        <Form
          name="wrap"
          labelCol={{ flex: '160px' }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          className="login-form"
          onFinish={statusButtonLogin ? loginSpam : onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: '有効なメールアドレスではありません。',
              },
              { required: true, message: 'メールアドレスを入力して下さい。' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'パスワードを入力して下さい。',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <GoogleReCaptchaProvider reCaptchaKey="6LcNmnAnAAAAAKSYp-z88-GzkQn9L-c9h8akM_1f">
            <GoogleReCaptcha onVerify={handleVerifyCapcha} />
          </GoogleReCaptchaProvider>
          <div className="option">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a className="login-form-forgot" target="_blank" href="https://hc.fortuna-inc.jp/sec/archives/docs/51">
              Forgot password
            </a>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={statusButtonLogin}>
              Log in
            </Button>
            {/* Or <Link to="/register">register now!</Link> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
