/**
 * @example const xxx = getLocalStorage('xxx_key'); // typeof xxx -> 'string'
 * @example const yyy = getLocalStorage<YYY_Type>('yyy_key'); // typeof yyy -> Object
 */
export const getLocalStorage = <T = string>(key: string): null | string | T => {
    if (typeof window === 'undefined') {
        return null;
    }
    const item = window.localStorage.getItem(key);
    if (item === null) {
        return null;
    }
    try {
        return JSON.parse(item);
    } catch {
        return item;
    }
};

/**
 * @example removeLocalStorage('xxx_key');
 */
export const removeLocalStorage = (key: string): void => {
    if (typeof window === 'undefined') {
        return;
    }
    window.localStorage.removeItem(key);
};

/**
 * @example setLocalStorage('xxx_key', 'xxx');
 * @example setLocalStorage('yyy_key', { yyy: 'yyy' });
 */
export const setLocalStorage = (key: string, value: string | { [key: string | number]: any }): void => {
    if (typeof window === 'undefined') {
        return;
    }
    if (typeof value === 'string') {
        window.localStorage.setItem(key, value);
    }
    window.localStorage.setItem(key, JSON.stringify(value));
};
