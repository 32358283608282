import { Link } from "react-router-dom";
import "./payment.scss";

export const PaymentSuccessShip = () => {
  return (
    <section className="payment">
      <div className="payment-wrap" style={{ textAlign: "center" }}>
        <h3 style={{ color: "#3cb878" }}>支払い成功</h3>
        <p>
          お客様のご注文は、お支払い手続きが完了いたしました。商品のお届けにつきましては、追ってご連絡させていただきます。
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px auto",
            color: "blue",
          }}
        >
          <Link to="/">トップページへ</Link>
          {/* <Link to="" style={{ marginLeft: "20px" }}>
            ご注文状況
          </Link> */}
        </div>
      </div>
    </section>
  );
};
