import ReactGA from "react-ga4";
import { Routes, Route } from "react-router-dom";
import { adminRoutes, customerRoutes, publicRoutes } from "./routes";
import DefaultLayout from "./layouts/DefaultLayout";
import { Fragment, Suspense, useEffect, useState } from "react";
import ProtectedRoutes from "./routes/protectedRoutes";
import Error from "./components/Error";
import { Spin } from "antd";
import { seoTagsApi } from "./api/seoTagsApi/seoTagsApi";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

interface SeoTag {
  name: string;
  content: string;
  location: number;
}

function App() {
  const [gaCode, setGaCode] = useState("");
  const [seoTag, setSeoTag] = useState<SeoTag[]>([]);

  const getAllSeoTags = async () => {
    try {
      const res: any = await seoTagsApi.getAllSeoTagCustomer();

      if (res?.status === "success") {
        /* Handle add scrip to head */
        const handleTagSeo = res.data?.filter(
          (script: any) => script.name !== "gaCode"
        );
        setSeoTag([...handleTagSeo]);

        const handleGaCode = res.data?.filter(
          (script: any) => script.name === "gaCode"
        );
        if (handleGaCode.length) {
          setGaCode(handleGaCode[0].content);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllSeoTags();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (gaCode) {
      ReactGA.initialize(gaCode);
    }
  }, [gaCode]);

  const publicRoute = publicRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = DefaultLayout;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const adminRoute = adminRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = DefaultLayout;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const customerRoute = customerRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;
    if (route.layout) {
      Layout = route.layout;
    } else {
      Layout = Fragment;
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  return (
    <div className="App">
      <Helmet>{seoTag?.map((script: SeoTag) => parse(script?.content))}</Helmet>
      <Suspense fallback={<Spin />}>
        <Routes>
          {/* {Login and Register} */}
          {publicRoute}

          {/* Page Admin */}
          <Route element={<ProtectedRoutes />}>{adminRoute}</Route>

          {/* page Customer */}
          {customerRoute}

          {/* Page Not Found */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
