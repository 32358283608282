import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Table, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addItem,
  getColorListItem,
  getProducts,
} from "../../../redux/services/productSlice";
import { getLocalStorage } from "../../../utils/localStorage";
import { useSelector } from "react-redux";
import { DataColorInfo, DataOptionColor } from "../../../types/styleColor";
import { ColumnsType } from "antd/es/table";

const ProductModal = ({ productId, dataSourceItem, text }: any) => {
  const role_user: any = getLocalStorage("role_user");
  const { listItem } = useSelector((state: any) => state.productReducer);

  const [dataInputAdd, setDataInputAdd] = useState("");
  const [dataDescription, setDataDescription] = useState("");
  const [colorList, setColorList] = useState<DataOptionColor[]>([]);
  const [colorListChoose, setColorListChoose] = useState<string[]>([]);
  const [statusGetColorList, setStatusGetColorList] = useState<boolean>(false);

  const [formProduct] = Form.useForm();
  const dispatch = useDispatch();

  const [api, showPopup] = notification.useNotification();
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const onFinish = async (productId: any) => {
    const dataAddItem = {
      name: dataInputAdd,
      product_id: productId,
      description: dataDescription,
      required: 0,
      colors: colorListChoose,
    };
    try {
      const res = await dispatch(addItem(dataAddItem));
      if (res.payload.status === "success") {
        alertSuccess("追加しました。");
        dispatch(getProducts());
        setDataInputAdd("");
      }
    } catch (err) {
      alertFail("追加に失敗しました。");
    }

    // TODO: add logic to handle adding the item
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    setColorList([]);
    getListColorItem(productId);
  };

  const handleOk = () => {
    formProduct
      .validateFields()
      .then(() => {
        // Form is valid, so submit data and reset form
        onFinish(productId);
        formProduct.resetFields();
        setIsModalOpen(false);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getListColorItem = async (productId: number) => {
    setStatusGetColorList(true);
    try {
      const res = await dispatch(getColorListItem(productId));
      if (res.payload.status) {
        const resData: DataColorInfo[] = res.payload.data;

        const temp: DataOptionColor[] = resData?.map(
          (color: DataColorInfo): DataOptionColor => ({
            id: color.id,
            key: color.id,
            name: color.name,
            color_code: color.color_code,
          })
        );
        setColorList([...temp]);
        setStatusGetColorList(false);
      } else {
        setColorList([]);
        setStatusGetColorList(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    render: (text: any, record: any, index: any) => {
      if (dataIndex === "color_code") {
        return (
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <span>{record.color_code}</span>
            <div
              className="color-box"
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: record.color_code,
              }}
            ></div>
          </Space>
        );
      }

      if (dataIndex === "color_name") {
        return (
          <span
            style={{
              color:
                record.name === "データを入力して下さい。" ? "#d2d2d2" : "",
            }}
          >
            {record.name}
          </span>
        );
      }
    },
  });

  const columnsColor: ColumnsType<DataOptionColor> = [
    // {
    //   title: "順",
    //   dataIndex: "no",
    //   align: "center",
    //   key: "no",
    //   render: (data, item, index) => <span>{index + 1}</span>,
    // },
    {
      title: "Key",
      dataIndex: "key",
      className: "hidden-row",
      key: "key-table",
      render: () => {
        return (
          <Form.Item name="key">
            <Input style={{ textAlign: "center" }} />
          </Form.Item>
        );
      },
    },
    {
      title: "カラーコード",
      dataIndex: "color_code",
      align: "center",
      key: "color_code",
      ...getColumnSearchProps("color_code"),
    },
    {
      title: "名前",
      dataIndex: "color_name",
      align: "center",
      key: "color_name",
      ...getColumnSearchProps("color_name"),
    },
  ];

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: DataOptionColor[]
    ) => {
      const temp: string[] = selectedRows?.map(
        (color: any) => color.color_code
      );
      setColorListChoose([...temp]);
    },
    getCheckboxProps: (record: DataOptionColor) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      {showPopup}
      {text ? (
        <Button
          disabled={
            Number(role_user) === 0 && listItem?.data?.length > 2 ? true : false
          }
          onClick={showModal}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          {" "}
          アイテムを追加
        </Button>
      ) : (
        <div onClick={showModal}>アイテムを追加</div>
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="追加"
        cancelText="キャンセル"
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          アイテムを追加
        </span>

        <Form
          form={formProduct}
          onFinish={onFinish}
          style={{ maxWidth: 600, marginTop: "10px" }}
          name="addProduct"
          autoComplete="off"
          layout="horizontal"
          scrollToFirstError
          labelCol={{ span: 3 }}
        >
          <Form.Item
            name="name"
            label="名前"
            rules={[
              {
                required: true,
                message: "名前を入力してください。",
              },
            ]}
          >
            <Input
              value={dataInputAdd}
              onChange={(e) => {
                setDataInputAdd(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item name="description" label="説明">
            <TextArea
              value={dataDescription}
              onChange={(e) => {
                setDataDescription(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item name="color_item" label="カラー">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="id"
              loading={statusGetColorList}
              columns={columnsColor}
              dataSource={colorList}
              bordered
              className="text-center"
              pagination={{
                defaultPageSize: 5,
              }}
              size="small"
            ></Table>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProductModal;
