import { Link, useParams } from "react-router-dom";
import "./payment.scss";
import { paymentApi } from "../../../api/paymentApi/paymentApi";
import { useEffect } from "react";

export const PaymentSuccessCard = () => {
  const { id } = useParams();
  const idPaymentSuccess = Number(id);

  const afterPaymentSuccess = async () => {
    try {
      const resData: any = await paymentApi.afterPaymentSuccess(
        idPaymentSuccess
      );

      if (resData.status === "success") {
        console.log("Done");
      }
    } catch (err) {
      console.log("Fail.");
    }
  };

  useEffect(() => {
    afterPaymentSuccess();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="payment">
      <div className="payment-wrap" style={{ textAlign: "center" }}>
        <h3 style={{ color: "#3cb878" }}>支払い成功</h3>
        <p>
          お客様のご注文は、お支払い手続きが完了いたしました。商品のお届けにつきましては、追ってご連絡させていただきます。
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px auto",
            color: "blue",
          }}
        >
          <Link to="/">トップページへ</Link>
          {/* <Link
            to={`/payment-info/${idPaymentSuccess}`}
            style={{ marginLeft: "20px" }}
          >
            ご注文状況
          </Link> */}
        </div>
      </div>
    </section>
  );
};
