import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { seoTagsApi } from "../../api/seoTagsApi/seoTagsApi";

export const getAllSeoTag: any = createAsyncThunk(
  "seo-tags/getAllSeoTag",
  async () => {
    const res = await seoTagsApi.getAllSeoTag();
    return res.data;
  }
);
export const addSeoTag: any = createAsyncThunk(
  "seo-tags/addSeoTag",
  async (data) => {
    const res = await seoTagsApi.addSeoTag(data);
    return res;
  }
);
export const updateSeoTagById: any = createAsyncThunk(
  "seo-tags/updateSeoTagById",
  async (data) => {
    const res = await seoTagsApi.updateSeoTagById(data);
    return res;
  }
);
export const deleteSeoTagById: any = createAsyncThunk(
  "seo-tags/deleteSeoTagById",
  async (id: number) => {
    const res = await seoTagsApi.deleteSeoTagById(id);
    return res;
  }
);

const seoTagSlice = createSlice({
  name: "seo-tags",
  initialState: {
    dataSeoTags: [],
    dataSeoTagsTmp: [],
    loading: false,
    status: "",
    idRowEdit: null,
  },
  reducers: {
    addRow(state: any, action) {
      //   state.dataSeoTags = [...current(state.dataSeoTags), action.payload];
      state.dataSeoTags = current(state.dataSeoTags).map((item: any) => {
        return {
          ...item,
          active: false,
        };
      });
      state.dataSeoTagsTmp = [action.payload];
    },

    editRow(state: any, action) {
      state.idRowEdit = action.payload;
      state.dataSeoTags = current(state.dataSeoTags)?.map((item: any) => {
        if (item.id === action.payload) {
          return {
            ...item,
            active: true,
          };
        } else {
          return {
            ...item,
            active: false,
          };
        }
      });
    },

    cancelRow(state: any, action) {
      if (action.payload === "edit") {
        state.dataSeoTags = current(state.dataSeoTags).map((item: any) => {
          return {
            ...item,
            active: false,
          };
        });
      } else {
        const tmpDataSource: any = [...current(state.dataSeoTags)].filter(
          (item: any) => item.id !== action.payload
        );
        const tmpDataSource2: any = [...current(state.dataSeoTagsTmp)].filter(
          (item: any) => item.id !== action.payload
        );
        state.dataSeoTags = [...tmpDataSource];
        state.dataSeoTagsTmp = [...tmpDataSource2];
      }
      state.status = "";
      state.idRowEdit = null;
    },

    setDataSeoTagsTmp(state: any, action) {
      state.dataSeoTagsTmp = [];
    },

    changeStatus(state: any, action) {
      state.status = action.payload;
    },
  },

  extraReducers: {
    /* Get All */
    [getAllSeoTag.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [getAllSeoTag.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [getAllSeoTag.fulfilled]: (state: any, action: any) => {
      state.loading = false;
      state.dataSeoTags = action.payload;
    },

    /* Add */
    [addSeoTag.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [addSeoTag.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [addSeoTag.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Edit */
    [updateSeoTagById.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [updateSeoTagById.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [updateSeoTagById.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },

    /* Delete */
    [deleteSeoTagById.pending]: (state: any, action: any) => {
      state.loading = true;
    },
    [deleteSeoTagById.reject]: (state: any, action: any) => {
      state.loading = false;
    },
    [deleteSeoTagById.fulfilled]: (state: any, action: any) => {
      state.loading = false;
    },
  },
});

export const { addRow, editRow, cancelRow, changeStatus, setDataSeoTagsTmp } =
  seoTagSlice.actions;
const { reducer: seoTagReducer } = seoTagSlice;
export default seoTagReducer;
