import {
  UserOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import patchDynamic from "../helper/pathDynamic";

export const sidebarItems = [
  {
    id: 1,
    path: `/admin-${patchDynamic}`,
    title: "ダッシュボード",
    icon: <DashboardOutlined />,
  },
  {
    id: 2,
    path: `/admin-${patchDynamic}/tag-seo`,
    title: "タグSEO",
    icon: <LineChartOutlined />,
  },
  {
    id: 3,
    path: `/admin-${patchDynamic}/account/selected-products`,
    title: "選択した製品",
    icon: <UserOutlined />,
  },
  {
    id: 4,
    path: "product",
    title: "商品",
    icon: <DatabaseOutlined />,
    children: [
      {
        id: 5,
        path: `/admin-${patchDynamic}/products/list-product`,
        title: "商品一覧",
      },
      {
        id: 6,
        path: `/admin-${patchDynamic}/products/add-product`,
        title: "商品を追加",
      },
    ],
  },
];
