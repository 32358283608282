import { Space, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { removeLocalStorage } from '../../utils/localStorage';
import patchDynamic from '../../helper/pathDynamic';
import { authApi } from '../../api';
import overLoader from '../overLoader';
import { useState } from 'react';

const Header = () => {
  const [statusLogout, setStatusLogout] = useState<Boolean>(false);
  const handleLogout = async () => {
    try {
      const res: any = await authApi.logout();
      if (res?.status === 'success') {
        setStatusLogout(true);
        removeLocalStorage('access_token');
        removeLocalStorage('email');
        removeLocalStorage('expires_at');
        removeLocalStorage('role_user');
        removeLocalStorage('openTab');
        // localStorage.removeItem('idTagActive');
        // localStorage.removeItem('idTabOpen');
        setTimeout(() => {
          setStatusLogout(false);
          return (window.location.href = `/login-${patchDynamic}`);
        }, 1000);
      }
    } catch (err) {
      console.log('Logout Fail.');
    }
  };

  return (
    <div className="header">
      {statusLogout ? overLoader() : ''}
      <div className="logo">
        <a href={`${window.location.protocol}//${window.location.host}/admin-${patchDynamic}`}>
          Color Simulation Admin
        </a>
      </div>
      <div className="header-right">
        <Space>
          <a href="https://hc.fortuna-inc.jp/sec/" target="noopener" className="helper" title="操作ヘルプ">
            操作ヘルプ
          </a>
          <div className="logout" onClick={() => handleLogout()}>
            <Button>
              <LogoutOutlined />
              ログアウト
            </Button>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default Header;
