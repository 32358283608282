import { configureStore } from "@reduxjs/toolkit";
import orderReducer from "../services/orderSlice";
import productReducer from "../services/productSlice";
import styleColorReducer from "../services/styleColorSlice";
import tagProductReducer from "../services/tagSlice";
import userReducer from "../services/userSlice";
import textStyleReducer from "../services/textStyleSlice";
import seoTagReducer from "../services/tagSeoSlice";

const store = configureStore({
  reducer: {
    productReducer: productReducer,
    userReducer: userReducer,
    orderReducer: orderReducer,
    styleColorReducer: styleColorReducer,
    textStyleReducer: textStyleReducer,
    tagProductReducer,
    seoTagReducer: seoTagReducer,
  },
});

export default store;
