import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { orderApi } from '../../api';

export const getOrders: any = createAsyncThunk('orders/getOrders', async () => {
  const res = await orderApi.getOrders();
  return res.data;
});

export const getOrdersWithPagination: any = createAsyncThunk('orders/getOrdersWithPagination', async (data) => {
  const res = await orderApi.getOrdersWithPagination(data);
  return res.data;
});

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    ordersWithPagination: [],
    loadingOrder: false,
  },
  reducers: {},
  extraReducers: {
    [getOrders.pending]: (state: any, action: any) => {
      state.loadingOrder = true;
    },
    [getOrders.reject]: (state: any, action: any) => {
      state.loadingOrder = false;
    },
    [getOrders.fulfilled]: (state: any, action: any) => {
      state.loadingOrder = false;
      state.orders = action.payload;
    },
    [getOrdersWithPagination.pending]: (state: any, action: any) => {
      state.loadingOrder = true;
    },
    [getOrdersWithPagination.reject]: (state: any, action: any) => {
      state.loadingOrder = false;
    },
    [getOrdersWithPagination.fulfilled]: (state: any, action: any) => {
      state.loadingOrder = false;
      state.ordersWithPagination = action.payload;
    },
  },
});

const { reducer: orderReducer } = orderSlice;

export default orderReducer;
