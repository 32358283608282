import HeaderCustomer from '../components/HeaderCustomer';
import '../assets/styles/Customer/style.scss';

const CustomerLayout = ({ children }: any) => {
  return (
    <>
      <HeaderCustomer />
      {children}
    </>
  );
};

export default CustomerLayout;
