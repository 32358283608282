import { Button, Collapse, Form, Input, Modal, Select, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import formatJapan from '../../../helper/formatJapan';
import { DataTypeStyleColor, ItemStyle } from '../../../types/styleColor';
import { facesApi, orderApi, productApi } from '../../../api';

import { DownOutlined, UpOutlined, DownloadOutlined } from '@ant-design/icons';
import icon_next from '../../../assets/images/icon-next.svg';
import { fontsApi } from '../../../api/fontsApi/fontsApi';
import FooterCustomer from '../../../components/FooterCustomer';
import urlApi from '../../../helper/urlApi';
import overLoader from '../../../components/overLoader';

interface ImageByStyleColorId {
  id_item: number;
  style_id: number;
  style_color_id: number;
}

export const PreviewProduct = () => {
  const { Panel } = Collapse;
  const { id } = useParams();
  const productDetail: any = { id };
  const [formText] = Form.useForm();

  const [api, showPopup] = notification.useNotification();
  const [showDes, setShowDes] = useState(false);
  const [showOption, setShowOption] = useState(true);
  const [infoProduct, setInfoProduct] = useState<any>({});
  const [idActive, setIdActive] = useState<number>(0);
  const [listItem, setListItem] = useState<ItemStyle[]>([]);
  const [listStyle, setListStyle] = useState<any[]>([]);
  const [listFace, setListFace] = useState<any[]>([]);
  const [listFont, setListFont] = useState<any[]>([]);
  const [styleActive, setStyleActive] = useState<any>({});
  const [subStyleActive, setSubStyleActive] = useState<any[]>([]);
  const [colorActive, setColorActive] = useState<any>({});
  const [listFaceWithProduct, setListFaceWithProduct] = useState<any[]>([]);
  const [listColorFilter, setListColorFilter] = useState<DataTypeStyleColor[]>([]);
  const [listColorSubStyle, setListColorSubStyle] = useState<any[]>([]);
  const [listTextStyle, setListTextStyle] = useState<any[]>([]);
  const [getStyleColorImageByStyleColorId, setGetStyleColorImageByStyleColorId] = useState<any[]>([]);
  const [styleColorTextStyle, setStyleColorTextStyle] = useState<any>({});
  const [listDataText, setListDataText] = useState<any[]>([]);
  const [roleUser, setRoleUser] = useState<number>(0);
  const [fontIdActive, setFontIdActive] = useState<number>(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [imageDowloadProduct, setImageDowloadProduct] = useState('');
  const [showModalDowload, setShowModalDowload] = useState(false);
  const [priceProduct, setPriceProduct] = useState<any>(0);
  const [priceStyle, setPriceStyle] = useState<any>({});
  const [existListImageStyle, setExistListImageStyle] = useState<number>(0);

  const handleShowModalDowload = () => {
    handleDowloadProduct();
    setShowModalDowload(true);
  };

  const handleOkModalDowload = () => {
    setShowModalDowload(false);
  };

  const handleCancelModalDowload = () => {
    setShowModalDowload(false);
  };

  // Setting Option For Slider Preview Product
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    fade: true,
    cssEase: 'linear',
  };

  // Alert.
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: 'topRight',
      duration: 1,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: 'topRight',
      duration: 3,
    });
  };

  // Modal List Item SP.
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setShowOption(!showOption);
  };

  // Handle Show Description.
  const handleShowDes = () => {
    setShowDes(!showDes);
  };
  // Handle Show Option.
  const handleShowOption = () => {
    setShowOption(!showOption);
    showModal();
  };
  // Handle Id Item Active.
  const handleIdActive = (id_active: any) => {
    if (id_active < listItem.length) {
      setIdActive(id_active);
    } else {
      setIdActive(0);
    }
  };
  // Get Price Style.
  const getPriceStyle = (idItem: number, listStyle: any, idStyle: number) => {
    const temp = listStyle?.filter((style: any) => style.id === idStyle);
    setPriceStyle({ ...priceStyle, [idItem]: temp[0].price });
  };
  // Handle Dowload Image Product.
  const handleDowloadProduct = async () => {
    const temp = handleFilterStyleColorWithId().filter((item: any) => item.face === 0);
    const handle_list_id_render = temp.map((item: any) => item.style_color_id);

    const handleDataOrders = {
      product_id: productDetail?.id,
      style_color_ids: handle_list_id_render.toString(),
    };
    try {
      const res: any = await orderApi.dowloadImageProduct(handleDataOrders);
      if (res.status === 'success') {
        setImageDowloadProduct(res?.data?.[0]?.image);
      }
    } catch (err) {
      alertFail('Download Fail.');
    }
  };
  // Get List Face Product.
  const getListFace = async () => {
    const res: any = await facesApi.getListFace();
    if (res.status === 'success') {
      setListFace(res.data);
    }
  };
  // Get List Face By Product ID.
  const listFaceByProduct = async (idProduct: any) => {
    setStatusLoader(true);
    const res: any = await productApi.getListFaceByProduct(idProduct);
    if (res.status === 'success') {
      setListFaceWithProduct(res.data);
      setStatusLoader(false);
    }
  };
  // Handle Filter Style Color With ID.
  const handleFilterStyleColorWithId = () => {
    const temp: any[] = [];
    listFaceWithProduct?.forEach((face: any) => {
      getStyleColorImageByStyleColorId?.forEach((item: ImageByStyleColorId) => {
        if (face.style_id === item.style_id && face.style_color_id === item.style_color_id) {
          temp.push(face);
        }
      });
    });
    return temp;
  };
  // Check Exist Recommend in Style.
  const checkExistRecommend = (data: any) => {
    const temp = data?.filter((item: any) => item.suggestion === 1);
    return temp;
    // }
  };
  // Get Role User.
  const getRole = async () => {
    try {
      const resData: any = await productApi.getCustomerRole();
      if (resData.status === 'success') {
        const role_user = resData.data.role;
        setRoleUser(role_user);
      }
    } catch (err) {
      console.log('Fail.');
    }
  };

  // ========================== HANDLE PRICE ALL ==========================

  const handlePriceStyle = () => {
    let sum = 0;
    const arrTemp = Object.values(priceStyle);
    arrTemp.forEach(function (value: any) {
      sum += value;
    });
    return sum;
  };

  const handlePriceSubStyle = () => {
    let sum = 0;
    const arrayTemp = subStyleActive?.map((subStyle: any) => subStyle?.priceSub);
    arrayTemp?.forEach(function (value: any) {
      sum += value;
    });
    return sum;
  };

  const handlePriceTextStyle = () => {
    if (styleColorTextStyle) {
      let sum = 0;
      const arrayTemp = Object.values(styleColorTextStyle);
      arrayTemp?.forEach(function (value: any) {
        sum += value[0].priceText;
      });
      return sum;
    }
  };

  // ================================= STYLE =================================

  // Get Style Active.
  const getStyleActive = (name_item: string, style_id: number, indexStyle: number, statusRecommend: number) => {
    // Clear Data Sub Style When Select Style.
    const temp = getStyleColorImageByStyleColorId.filter((item: any) => item.parent_id === -1);
    setGetStyleColorImageByStyleColorId([...temp]);

    // =============

    setStyleActive({ ...styleActive, ...{ [name_item]: style_id } });
    setColorActive({ ...colorActive, ...{ [name_item]: -1 } }); // Not exist color.
    handleGetColorByStyleIndex(indexStyle);
  };
  // Get Color By Style Index.
  const handleGetColorByStyleIndex = (indexStyle: number) => {
    if (listStyle[idActive]) {
      const getColors = listStyle[idActive][indexStyle]?.style_color || []; // List Color For Parent
      const getColorsSubStyle = listStyle[idActive][indexStyle]?.sub_style || []; // List Color For Sub Style

      setListColorFilter(getColors);
      setListColorSubStyle(getColorsSubStyle);
    }
  };
  // Check Exits Style Color Image.
  const checkExitsStyleColorImage = (id_item: number, data: ImageByStyleColorId) => {
    // Clear Data Sub Style ( Click Select Style Color Parent -> Clear Select Style Color Sub Style )
    const tempSubStyleColorActive = [...getStyleColorImageByStyleColorId].filter(
      (item: any) => item.parent_id !== data.style_id,
    );
    const tempSubStyleActive = subStyleActive.filter((style: any) => style.parent_id !== data.style_id);
    setGetStyleColorImageByStyleColorId([...tempSubStyleColorActive]);
    setSubStyleActive([...tempSubStyleActive]);

    // ====================

    // Check Exits
    const check = [...tempSubStyleColorActive].filter((item: ImageByStyleColorId) => item.id_item === id_item);
    if (!check.length) {
      setGetStyleColorImageByStyleColorId([...tempSubStyleColorActive, { ...data, id_item, parent_id: -1 }]);
    } else {
      const temp = [...tempSubStyleColorActive].map((item: ImageByStyleColorId) =>
        id_item === item.id_item ? { ...item, ...data } : item,
      );
      setGetStyleColorImageByStyleColorId([...temp]);
    }
  };
  // Handle Select Style Color.
  const handleSelectStyleColor = (name_item: string, id_item: number, color_id: number, data: any) => {
    // Style + Sub Style
    setColorActive({ ...colorActive, ...{ [name_item]: color_id } });
    checkExitsStyleColorImage(id_item, data);
  };

  // ================================= SUB STYLE =================================

  // Check Exits Sub Style Color Image.
  const checkExitsSubStyleColorImage = (id_subStyle: number, parent_id: number = -1, data: any) => {
    const check = getStyleColorImageByStyleColorId.filter((item: ImageByStyleColorId) => item.id_item === id_subStyle);
    if (!check.length) {
      setGetStyleColorImageByStyleColorId([
        ...getStyleColorImageByStyleColorId,
        { ...data, id_item: id_subStyle, parent_id },
      ]);
    } else {
      const temp = getStyleColorImageByStyleColorId.map((item: ImageByStyleColorId) =>
        id_subStyle === item.id_item ? { ...item, ...data, parent_id } : item,
      );
      setGetStyleColorImageByStyleColorId([...temp]);
    }
  };
  // Handle Select Sub Style Color.
  const handleSelectSubStyleColor = (
    id_subStyle: number,
    idSubStyleColor: number,
    style_id: number,
    parent_id: number,
    priceSub: number,
  ) => {
    checkExitsSubStyleColorImage(id_subStyle, parent_id, { id_subStyle, style_color_id: idSubStyleColor, style_id });

    if (!subStyleActive.length) {
      setSubStyleActive([...subStyleActive, { idSubStyleColor, style_id, parent_id, priceSub }]);
    } else {
      const checkExist = subStyleActive.filter((style: any) => style_id === style.style_id);
      if (checkExist.length) {
        const temp = subStyleActive.map((style: any) =>
          style.style_id === style_id ? { ...style, idSubStyleColor, priceSub } : style,
        );
        setSubStyleActive([...temp]);
      } else {
        setSubStyleActive([...subStyleActive, { idSubStyleColor, style_id, parent_id, priceSub }]);
      }
    }
  };

  // ================================= FONTS =================================

  // Get List Font By Style.
  const getListFont = (data: any) => {
    const temp = data?.styles?.filter((style: any, index: number) => styleActive[data.name] === style.id)[0];
    return temp?.text_content;
  };
  const getListFontText = async () => {
    const resData: any = await fontsApi.getAllFonts();
    setListFont(resData.data);
  };
  // Handle Set Font Active.
  const handleFontActive = (nameItem: string, fontId: any) => {
    setFontIdActive(fontId);
    const dataStyleColorText: any = {
      ...styleColorTextStyle,
      [nameItem]: [
        {
          idFontActive: fontId,
          styleColorId: -1,
          priceText: 0,
        },
      ],
    };
    setStyleColorTextStyle({ ...dataStyleColorText });
  };
  // Check Exits Text Style Color Image
  const checkExistTextStyleImage = (data: any) => {
    const check = [...getStyleColorImageByStyleColorId].filter(
      (item: ImageByStyleColorId) => item.id_item === data.idTextStyleColor,
    );

    if (!check.length) {
      setGetStyleColorImageByStyleColorId([
        ...getStyleColorImageByStyleColorId,
        { id_item: data.idTextStyleColor, style_id: data.style_id, style_color_id: data.style_color_id, parent_id: -1 },
      ]);
    } else {
      const temp = [...getStyleColorImageByStyleColorId].map((item: ImageByStyleColorId) =>
        item.id_item === data.idTextStyleColor
          ? { ...item, style_id: data.style_id, style_color_id: data.style_color_id, parent_id: -1 }
          : item,
      );
      setGetStyleColorImageByStyleColorId([...temp]);
    }
  };
  // Get Style Color Image Text.
  const handleListStyleColorImageText = (font_id_active: number) => {
    const style_id_active = styleActive[listItem[idActive]?.name];
    const listStyleTemp: any = listStyle[idActive];
    const dataText = listStyleTemp?.filter((style: any) => style.id === style_id_active);
    const filterTextWithFontId = dataText?.[0]?.text_content?.[0]?.font?.filter(
      (text: any) => text.id === font_id_active,
    );
    return filterTextWithFontId?.[0]?.text_style_color;
  };
  // Clear Text Style When Select Style.
  const clearTextStyle = (idTextStyle: number) => {
    // Clear Data Text In List Render.
    const temp = [...getStyleColorImageByStyleColorId].map((item: any) =>
      item.id_item === idTextStyle
        ? {
            ...item,
            style_id: -1,
            style_color_id: -1,
          }
        : item,
    );
    setGetStyleColorImageByStyleColorId([...temp]);

    // Clear Data Text Active.
    const dataTextClear = {
      ...styleColorTextStyle,
      [listItem[idActive]?.name]: [
        {
          idFontActive: listFont?.[0]?.id,
          styleColorId: -1,
          priceText: 0,
        },
      ],
    };
    setStyleColorTextStyle({ ...dataTextClear });
  };
  // Select Style Color Text.
  const handleSelectStyleColorText = (data: any) => {
    const nameItem = listItem[idActive]?.name;

    if (data) {
      checkExistTextStyleImage(data); // Get Style Color Text.
    }

    const dataStyleColorText = {
      ...styleColorTextStyle[nameItem]?.[0],
      idFontActive: fontIdActive,
      styleColorId: data.style_color_id,
      priceText: data.price,
    };
    setStyleColorTextStyle({ ...styleColorTextStyle, [data.name_item]: [dataStyleColorText] });
  };
  // Handle Save Text.
  const handleSaveText = (name_item: string, value: any) => {
    formText.resetFields(); // Clear Form When Submit.
    alertSuccess('Save Text Success!');

    const dataText = {
      name_item,
      text_value: value.text_style,
      style_color_id: styleColorTextStyle[listItem[idActive]?.name]?.[0]?.styleColorId,
    };
    const checkExist = listDataText.filter((text: any) => text.name_item === name_item); //Check Exist Value Text In List Data Text.
    if (!checkExist.length) {
      setListDataText([...listDataText, dataText]);
    } else {
      const handleDataText = listDataText.map((text: any) => (text.name_item === name_item ? dataText : text));
      setListDataText([...handleDataText]);
    }
  };
  // Get Text Style Change.
  const getTextStyle = () => {
    const valueText = listDataText?.filter((text: any) => text.name_item === listItem[idActive]?.name)?.[0]?.text_value;
    return valueText;
  };

  const checkExistListImageStyle = () => {
    const checked: any = listItem[idActive]?.styles?.filter((style: any) => style.image);
    setExistListImageStyle(checked?.length);
  };

  // =================================

  useEffect(() => {
    // Get Role
    getRole();

    // Get List Face
    getListFace();

    // Get List Font
    getListFontText();

    // Get Data
    const getListItem = async () => {
      const resData: any = await productApi.getPrivateProductById(productDetail?.id);
      setInfoProduct({
        name: resData.data.name,
        price: resData.data.price,
      });

      setPriceProduct(resData.data.price);

      const listItem = await resData.data.items;
      const listStyle = listItem.map((item: any) => item.styles);
      setListItem(listItem);
      setListStyle(listStyle);
    };

    getListItem(); // Get List Item Product
    getListFace(); // Get List Face Product
    listFaceByProduct(productDetail.id); // Get List Face With Product ID
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle List Color Select Default.
  useEffect(() => {
    if (listItem[idActive]) {
      const nameItemActive = listItem[idActive].name;
      const idStyleActive = styleActive[nameItemActive];
      const listStyleItemActive = listItem[idActive]?.styles;

      const checkExistStyle = styleActive.hasOwnProperty(nameItemActive); // Check Exist Style.
      const checkExistColor = colorActive.hasOwnProperty(nameItemActive); // Check Exist Color.

      // Set Active Default for Style ( If you don't recommend, skip it ).
      if (!checkExistStyle) {
        setStyleActive({
          ...styleActive,
          ...{
            [nameItemActive]: checkExistRecommend(listStyleItemActive)[0]?.id,
          },
        });
      }

      // Set Active Default for Color ( If you don't recommend, skip it ).
      if (!checkExistColor) {
        setColorActive({
          ...colorActive,
          ...{
            [nameItemActive]: checkExistRecommend(listStyleItemActive)[0]?.style_color[0]?.color_id,
          },
        });
      }

      // Get List Color First Loader.
      const listColor = listStyle[idActive]?.filter((style: any) => style.id === idStyleActive);
      if (listColor?.length) {
        const listColorImage = listColor[0].style_color;
        const listSubColorImage = listColor[0].sub_style;
        setListColorFilter(listColorImage);
        setListColorSubStyle(listSubColorImage);
      } else {
        setListColorFilter([]);
        setListColorSubStyle([]);
      }

      // Get Image In List Style.
      const checked: any = listStyleItemActive?.filter((style: any) => style.image);
      setExistListImageStyle(checked?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idActive, listItem, listStyle, styleActive]);

  // Set List Style Color Select Default.
  useEffect(() => {
    const setSelectStyleColorDefault = async () => {
      const getListStyleColorDefault: any = [];
      let tempPriceStyle: any = {}; // Handle Price Style.

      listItem?.forEach((item: any) => {
        //  ===========================
        // Handle Price Style.
        if (item?.styles?.length) {
          tempPriceStyle[item?.id] = item?.styles?.[0]?.price;
        }
        //  ===========================

        item?.styles?.forEach((i: any) => {
          if (i?.suggestion === 1) {
            if (i?.text_content?.length) {
              getListStyleColorDefault.push({
                id_item: item.id * 100 ** 3,
                style_id: i?.text_content?.[0]?.font?.[0]?.text_style_color?.[0]?.style_id,
                style_color_id: i?.text_content?.[0]?.font?.[0]?.text_style_color?.[0]?.id,
                parent_id: -1,
              });
            }
            getListStyleColorDefault.push({
              id_item: item.id,
              style_id: i?.style_color[0]?.style_id,
              style_color_id: i?.style_color[0]?.id,
              parent_id: -1,
            });
          }
        });
      });

      setGetStyleColorImageByStyleColorId(getListStyleColorDefault);
      setPriceStyle({ ...tempPriceStyle });
    };

    setSelectStyleColorDefault(); // Set Item Render Default.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItem, listFont]);

  // Set Text Render Default.
  useEffect(() => {
    const setStyleColorTextDefault = async () => {
      let valueTemp: any = {};
      if (listItem && listFont) {
        listItem.forEach((item: any) => {
          const nameItemActive = item.name;

          if (item.styles?.[0]?.text_content?.length) {
            valueTemp[nameItemActive] = [
              {
                idFontActive: listFont?.[0]?.id,
                styleColorId: item.styles[0].text_content[0].font[0].text_style_color[0].id,
                priceText: item.styles[0].text_content[0].font[0].text_style_color[0].price,
              },
            ];
          }
        });
      }
      setStyleColorTextStyle({ ...valueTemp });
    };

    setStyleColorTextDefault(); // Set Text Render Default.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItem, listFont]);

  // Set Font Active Default
  useEffect(() => {
    setListTextStyle(listStyle[idActive]?.[0]?.text_content); // Get All Text Of Style.
    setFontIdActive(listFont?.[0]?.id); // Set ID Font Active.
    // eslint-disable-next-line
  }, [listFont, listItem, idActive, listDataText]);

  // Update Data Form
  useEffect(() => {
    formText.setFieldsValue({ text_style: getTextStyle() });
    // eslint-disable-next-line
  }, [formText, listDataText, idActive, listItem]);

  // =================================

  return (
    <>
      {showPopup}
      <section className="main new_index">
        {statusLoader ? overLoader() : ''}
        <div className="container">
          <div className="setting_product">
            <div className="text-product">
              <p>
                {infoProduct.name}
                <span className="price-product">
                  {formatJapan.format(
                    priceProduct + handlePriceSubStyle() + handlePriceStyle() + handlePriceTextStyle() || 0,
                  )}
                </span>
              </p>
              {listItem.map((item: any, index: number) =>
                index === idActive ? (
                  // Description PC
                  <div key={item.id} className="block-note">
                    <span>注意事項</span>
                    <p>{item.description}</p>
                  </div>
                ) : (
                  ''
                ),
              )}
              {/* <div className="btn btn-show-option sp custom-btn" onClick={handleShowOption}>
                <p>メニュー</p>
              </div> */}
              <div className="sp">
                <div className="block_button_main flex-center mb-80" style={{ margin: 0 }}>
                  <Button
                    className="btn-dowload-image"
                    ghost
                    shape="circle"
                    icon={<DownloadOutlined />}
                    size="large"
                    // style={{ marginRight: 10 }}
                    onClick={handleShowModalDowload}
                  />
                  {/* <Button
                    disabled
                    className="btn_main btn-save-image custom-btn"
                    style={{ marginRight: 0 }}
                    onClick={handleOrders}
                  >
                    完了
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="preview-product mb-80">
              <Slider {...settings}>
                {listFace.map((face: any, index: number) => (
                  <div className="wrap-image" key={index}>
                    {handleFilterStyleColorWithId()?.map((item: any) =>
                      item.face === face.face ? (
                        <img
                          className="img-slide"
                          key={item.id}
                          style={{ zIndex: item.item_index }}
                          src={item.image}
                          alt=""
                        />
                      ) : (
                        ''
                      ),
                    )}
                  </div>
                ))}
              </Slider>
              {/* <div className="pc">
                <div className="block_button_main flex-center mb-80">
                  <Button
                    className="btn-dowload-image"
                    ghost
                    shape="circle"
                    icon={<DownloadOutlined />}
                    size="large"
                    style={{ marginRight: 20 }}
                    onClick={handleShowModalDowload}
                  />
                  <button
                    className="btn_main btn-save-image custom-btn"
                    style={{ marginRight: 0 }}
                    onClick={handleOrders}
                  >
                    完了
                  </button>
                </div>
              </div> */}
            </div>
            <div className="option-product">
              {/* Button Show Option For PC */}
              <div className="option-product-header pc-flex" style={showOption ? { height: 0 } : {}}>
                {showOption ? '' : <span onClick={() => setShowOption(!showOption)}>×</span>}
              </div>

              {/* Description SP */}
              <div className="product-description sp">
                <p onClick={() => handleShowDes()}>注意事項 {showDes ? <UpOutlined /> : <DownOutlined />}</p>
                {showDes ? (
                  <div className="content" style={{ padding: '0 1.5rem 1rem' }}>
                    {listItem.map((item: any, index: number) => (index === idActive ? <>{item.description}</> : ''))}
                  </div>
                ) : (
                  ''
                )}
              </div>

              {/* List Styles */}
              {showOption ? (
                <div className="option-product-main">
                  <div className="pagination-product">
                    {idActive > 0 ? (
                      <img
                        className="icon-pre"
                        src={icon_next}
                        alt=""
                        onClick={() => {
                          handleIdActive(idActive - 1);
                          checkExistListImageStyle();
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {listItem.map((item: any, index: number) =>
                      index === idActive ? (
                        <div className="info-item" key={item.id}>
                          <div className="sp">
                            <div className="wrapper-name-style" onClick={handleShowOption}>
                              <p>{item.name}</p>
                              <span>{`${index + 1} / ${listItem.length}`}</span>
                            </div>
                          </div>
                          <div className="pc">
                            <div className="wrapper-name-style" onClick={() => setShowOption(!showOption)}>
                              <p>{item.name}</p>
                              <span>{`${index + 1} / ${listItem.length}`}</span>
                            </div>
                          </div>
                          <img
                            className="icon-next"
                            src={icon_next}
                            alt=""
                            onClick={() => {
                              handleIdActive(idActive + 1);
                              checkExistListImageStyle();
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      ),
                    )}
                  </div>
                  {listItem.map((item: any, index: number) =>
                    index === idActive ? (
                      <div className="box-select" key={item.id}>
                        <div className="list-part">
                          {item.styles.map((style: any, index: number) => (
                            <div
                              key={style.id}
                              onClick={() => {
                                getStyleActive(item.name, style.id, index, style.suggestion);
                                clearTextStyle(item.id * 100 ** 3); // Clear Data Text When Select Style.
                                setImageDowloadProduct(''); // Clear Image Download.
                              }}
                              className={`category-item 
                                ${styleActive[item.name] === style.id ? 'active' : ''} 
                                ${style.suggestion === 1 ? 'recommend' : ''}
                                ${!(existListImageStyle === listItem[idActive]?.styles?.length) ? 'border-30' : ''}
                              `}
                            >
                              {existListImageStyle === listItem[idActive]?.styles?.length ? (
                                <>
                                  <img src={style.image} alt="#" />
                                  <p>{style.name}</p>
                                </>
                              ) : (
                                <p style={{ margin: 0 }}>{style.name}</p>
                              )}
                            </div>
                          ))}
                        </div>
                        <div>
                          {listColorFilter.length ? (
                            <>
                              {/* <p className="list-color-title">カラーボード</p> */}
                              <div className="list-color">
                                {listColorFilter?.map((color: DataTypeStyleColor) => (
                                  <div className="wrap-color-item" key={color.id}>
                                    <div
                                      onClick={() => {
                                        handleSelectStyleColor(item.name, item.id, color.color_id, {
                                          style_id: color.style_id,
                                          style_color_id: color.id,
                                        });
                                        getPriceStyle(item.id, item.styles, styleActive[item.name]);
                                        // clearTextStyle(item.id * 100 ** 3); // Clear Data Text When Select Style.
                                        setImageDowloadProduct(''); // Clear Image Download.
                                      }}
                                      className={`color-item ${
                                        colorActive[item.name] === color.color_id ? 'active' : ''
                                      }`}
                                      style={{
                                        backgroundColor: color.color_code,
                                      }}
                                    >
                                      <p>{color.color_name}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </div>

                        {/* List Sub Style */}
                        {listColorSubStyle?.length ? (
                          <div className="list-sub-style">
                            <Collapse accordion defaultActiveKey={['1']}>
                              {listColorSubStyle?.map((subStyle: any) => (
                                <Panel header={subStyle?.name} key={subStyle?.id}>
                                  <div className="list-color">
                                    {subStyle?.style_color?.map((color: any) => (
                                      <div className="wrap-color-item" key={color.id}>
                                        <div
                                          onClick={() => {
                                            handleSelectSubStyleColor(
                                              subStyle.id * 100 ** 4, // Fix Duplicate ID SubStyle and ID Item.
                                              color.id,
                                              color.style_id,
                                              color.parent_id,
                                              subStyle.price,
                                            );
                                            setImageDowloadProduct(''); // Clear Image Download.
                                          }}
                                          className={`color-item ${
                                            subStyleActive.filter((style: any) => style.idSubStyleColor === color.id)
                                              .length
                                              ? 'active'
                                              : ''
                                          }`}
                                          style={{
                                            backgroundColor: color.color_code,
                                          }}
                                        >
                                          <p>{color.color_name}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Panel>
                              ))}
                            </Collapse>
                          </div>
                        ) : (
                          ''
                        )}

                        {/* List Text Style */}
                        {roleUser && listTextStyle?.length ? (
                          <Collapse className="list-text-style">
                            <Panel header="Text Style" key="1">
                              <div style={{ textAlign: 'center' }}>
                                {getListFont(item)?.length
                                  ? getListFont(item)?.map((font: any) => (
                                      <div className="wrap-text" key={font.id}>
                                        <div className="wrap-font">
                                          <p>Font Text</p>
                                          <Select
                                            labelInValue
                                            defaultValue={{
                                              value: listFont?.filter((font: any) => font.id === fontIdActive)?.[0]?.id,
                                              label: listFont?.filter((font: any) => font.id === fontIdActive)?.[0]
                                                ?.name,
                                            }}
                                            style={{ width: '70%', marginTop: 10 }}
                                            onChange={(value: any) => handleFontActive(item.name, value.key)}
                                            options={listFont?.map((font: any) => ({
                                              value: font.id,
                                              label: font.name,
                                            }))}
                                          />
                                          {/* <div className="list-fonts">
                                            {listFont?.map((font: any) => (
                                              <img
                                                key={font.id}
                                                src={font.image}
                                                alt={font.name}
                                                title={font.name}
                                                className={`${
                                                  styleColorTextStyle[listItem[idActive]?.name]?.[0]?.idFontActive ===
                                                  font.id
                                                    ? 'active'
                                                    : ''
                                                }`}
                                                onClick={() => handleFontActive(item.name, font.id)}
                                              />
                                            ))}
                                          </div> */}
                                        </div>
                                        <p className="name-font">{font.name}</p>
                                        <Form
                                          form={formText}
                                          onFinish={(e) => handleSaveText(item.name, e)}
                                          initialValues={{
                                            text_style: getTextStyle(),
                                          }}
                                        >
                                          <Form.Item
                                            name="text_style"
                                            style={{ margin: 0 }}
                                            rules={[
                                              {
                                                max: handleListStyleColorImageText(fontIdActive)?.[0]?.length_limit,
                                                message: `最⼤${
                                                  handleListStyleColorImageText(fontIdActive)?.[0]?.length_limit
                                                }⽂字までとなります。`,
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Please enter data" allowClear />
                                          </Form.Item>

                                          {/* List Color Text */}
                                          <div className="list-color">
                                            {handleListStyleColorImageText(fontIdActive)?.map((color: any) => (
                                              <div className="wrap-color-item" key={color.id}>
                                                <div
                                                  onClick={() => {
                                                    handleSelectStyleColorText({
                                                      name_item: item.name,
                                                      idTextStyleColor: item.id * 100 ** 3, // Fix Duplicate ID TextStyle and ID Item.
                                                      style_id: color.style_id,
                                                      style_color_id: color.id,
                                                      price: color.price,
                                                    });
                                                    setImageDowloadProduct(''); // Clear Image Download.
                                                  }}
                                                  className={`color-item ${
                                                    styleColorTextStyle[listItem[idActive]?.name]?.[0]?.styleColorId ===
                                                    color.id
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  style={{ backgroundColor: color.color_code }}
                                                >
                                                  <p>{color.color_name}</p>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          <Button
                                            className="btn-save-text"
                                            htmlType="submit"
                                            disabled={
                                              !(
                                                styleColorTextStyle[listItem[idActive]?.name]?.[0]?.idFontActive &&
                                                styleColorTextStyle[listItem[idActive]?.name]?.[0]?.styleColorId !== -1
                                              )
                                            }
                                            type="primary"
                                          >
                                            決定
                                          </Button>
                                        </Form>

                                        {/* <div className="list-color">
                                          {handleListStyleColorImageText(fontIdActive)?.map((color: any) => (
                                            <div className="wrap-color-item" key={color.id}>
                                              <div
                                                onClick={() => {
                                                  handleSelectStyleColorText({
                                                    name_item: item.name,
                                                    idTextStyleColor: item.id * 100 ** 3, // Fix Duplicate ID TextStyle and ID Item.
                                                    style_id: color.style_id,
                                                    style_color_id: color.id,
                                                  });
                                                }}
                                                className={`color-item ${
                                                  styleColorTextStyle[listItem[idActive]?.name]?.[0]?.styleColorId ===
                                                  color.id
                                                    ? 'active'
                                                    : ''
                                                }`}
                                                style={{ backgroundColor: color.color_code }}
                                              >
                                                <p>{color.color_name}</p>
                                              </div>
                                            </div>
                                          ))}
                                        </div> */}
                                      </div>
                                    ))
                                  : ''}
                              </div>
                            </Panel>
                          </Collapse>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    ),
                  )}
                  <div className="pc" style={{ marginTop: '4rem' }}>
                    <div className="flex-center">
                      <Button
                        className="btn-dowload-image"
                        ghost
                        shape="circle"
                        icon={<DownloadOutlined />}
                        size="large"
                        // style={{ marginRight: 20 }}
                        onClick={handleShowModalDowload}
                      />
                      {/* <button
                        disabled
                        className="btn_main btn-save-image custom-btn"
                        style={{ marginRight: 0 }}
                        onClick={handleOrders}
                      >
                        完了
                      </button> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="list-item-option pc">
                  <ul>
                    {listItem?.map((item: any, index: number) => (
                      <li
                        key={item.id}
                        className={item.id === listItem[idActive].id ? 'active' : ''}
                        onClick={() => {
                          setIdActive(index);
                          setShowOption(!showOption);
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {FooterCustomer()}

        {/* ========= Modal List Item For SP ========= */}
        <Modal
          style={{ top: 120 }}
          className="modal-list-item"
          title="Basic Modal"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <ul>
            {listItem?.map((item: any, index: number) => (
              <li
                key={item.id}
                className={item.id === listItem[idActive].id ? 'active' : ''}
                onClick={() => {
                  setIdActive(index);
                  handleCancel();
                }}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </Modal>

        {/* ========= Modal Download Image Product ========= */}
        <Modal
          title="Basic Modal"
          open={showModalDowload}
          centered
          onOk={handleOkModalDowload}
          onCancel={handleCancelModalDowload}
          footer={null}
          className="modal-dowload-image"
        >
          {imageDowloadProduct ? (
            <img src={imageDowloadProduct} alt="" />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            {imageDowloadProduct ? (
              <Button
                type="primary"
                className="click-btn-save-image flex-box-center"
                href={`${urlApi}/customer/download-order-images?image_path=${imageDowloadProduct}`}
              >
                ダウンロード
              </Button>
            ) : (
              ''
            )}
          </div>
        </Modal>
      </section>
    </>
  );
};
