import axiosClient from "../axiosClient";

export const orderApi = {
  async getOrders() {
    const url = "/orders";
    return axiosClient.get(url);
  },

  async getOrdersById(id: number) {
    const url = `/customer/orders/${id}`;
    return axiosClient.get(url);
  },

  async addTempOrders(data: any) {
    const url = "/customer/temp-orders";
    return axiosClient.post(url, data);
  },

  async addOrders(data: any) {
    const url = "/customer/orders";
    return axiosClient.post(url, data);
  },

  async dowloadImageProduct(data: any) {
    const url = "/customer/order-images";
    return axiosClient.post(url, data);
  },

  async getOrdersWithPagination(data: any) {
    const url = `/orders?page=${data.page_index}&page_size=${data.page_size}`;
    return axiosClient.get(url);
  },

  async getOrdersDetail(orderId: number) {
    const url = `/orders/get-payment-order/${orderId}`;
    return axiosClient.get(url);
  },
};
