import { Menu, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ShoppingOutlined, ApartmentOutlined, SettingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getProducts } from '../../redux/services/productSlice';
import ProductModal from '../../modules/admin/productModal';
import { sidebarItems } from '../../utils/sidebarItem';
import { getLocalStorage } from '../../utils/localStorage';
import patchDynamic from '../../helper/pathDynamic';

const { Sider } = Layout;

const Sidebar = () => {
  const role_user: any = getLocalStorage('role_user');
  const { listItem } = useSelector((state: any) => state.productReducer);

  const { product_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [idTabOpen, setIdTabOpen] = useState<any[]>([]);
  const { products } = useSelector((state: any) => state.productReducer);

  const idOpen = localStorage.getItem('openTab') === null ? [] : JSON.parse(localStorage.getItem('openTab')!);

  useEffect(() => {
    if (idTabOpen?.length === 0 && idOpen.length === 0) {
      if (
        location.pathname === `/admin-${patchDynamic}/products/list-product` ||
        location.pathname === `/admin-${patchDynamic}/products/add-product`
      ) {
        setIdTabOpen(['product']);
      }
    } else {
      setIdTabOpen(idOpen);
    }
    if (product_id !== undefined) {
      setIdTabOpen([product_id]);
    }
    // eslint-disable-next-line
  }, [product_id]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const onOpenChange = (keys: any) => {
    setIdTabOpen(keys);
    localStorage.setItem('openTab', JSON.stringify(keys));
  };

  const SidebarItemsRender = sidebarItems.map((sidebarItem) => {
    if (!sidebarItem.children) {
      return (
        <Menu.Item
          key={sidebarItem.path}
          onClick={() => {
            localStorage.removeItem('openTab');
          }}
        >
          <Link to={sidebarItem.path}>
            {sidebarItem.icon}
            <span>{sidebarItem.title}</span>
          </Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.SubMenu
          key={sidebarItem.path}
          title={sidebarItem.title}
          icon={sidebarItem.icon}
          className="border-dotted"
        >
          {sidebarItem.children.map((childrenItem) => {
            return (
              <Menu.Item key={childrenItem.path}>
                <Link to={childrenItem.path}>
                  <span>{childrenItem.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    }
  });

  const productRender = products?.map((item: any) => {
    return (
      <Menu.SubMenu key={`${item.id}`} title={item.name} icon={<ShoppingOutlined />}>
        <Menu.Item key={`/admin-${patchDynamic}/products/${item.id}/setting`} icon={<SettingOutlined />}>
          <Link
            to={`/admin-${patchDynamic}/products/${item.id}/setting`}
            state={{
              nameProduct: item.name,
              priceProduct: item.price,
              descriptionProduct: item.description,
              imgBase64: item.image_base64,
            }}
          >
            <span>設定</span>
          </Link>
        </Menu.Item>
        {item.items?.map((child: any) => (
          <Menu.Item key={`/admin-${patchDynamic}/products/${item.id}/item/${child.id}`} icon={<ApartmentOutlined />}>
            <Link to={`/admin-${patchDynamic}/products/${item.id}/item/${child.id}`} state={{ nameProduct: item.name }}>
              <span>{child.name}</span>
            </Link>
          </Menu.Item>
        ))}
        {Number(role_user) === 0 && listItem?.length > 2 ? (
          ''
        ) : (
          <Menu.Item key={`add-item-${item.id}`} icon={<PlusCircleOutlined />}>
            <ProductModal handleListProduct={getProducts} productId={item.id} />
          </Menu.Item>
        )}
      </Menu.SubMenu>
    );
  });

  return (
    <Sider width={280} className="site-layout-background">
      <Menu
        mode="inline"
        style={{ height: '100%' }}
        key={product_id}
        selectedKeys={[location.pathname]}
        openKeys={idTabOpen}
        onOpenChange={onOpenChange}
      >
        {SidebarItemsRender}
        {productRender}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
